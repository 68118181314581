import { Box } from "grommet";
export function BottomPad() {
  return (
    <Box
      style={{
        height: 100,
      }}
    />
  );
}
